/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    strong: "strong",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Cuando estuve informándome sobre ", React.createElement(_components.a, {
    href: "http://litox.entramado.net/2010/02/22/openid/"
  }, "OpenID"), " y OAuth descubrí que eran dos cosas bastante distintas, el primero es un sistema de Identificación y el segundo de Autorización, pero ¿donde está la diferencia?"), "\n", React.createElement(_components.p, null, "La ", React.createElement(_components.strong, null, "Identificación"), " es como cuando recoges un paquete certificado, cuando enseñas tu carnet de identidad y firmas estás diciendo que tu eres ", React.createElement(_components.em, null, "esa"), " persona y tienes permiso para recoger el paquete."), "\n", React.createElement(_components.p, null, "La ", React.createElement(_components.strong, null, "Autorización"), ", es cuando le das un escrito a alguien con tu firma para que vaya a recoger el paquete, le estas dando permiso, pero esa persona no tiene mas permisos, solo el que tu le des."), "\n", React.createElement(_components.p, null, "No es una gran explicación, para mas detalles os dejo una explicación mucho más detallada."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://www.ldc.usb.ve/~figueira/Cursos/Seguridad/Expo/OAuth_expo.pdf"
  }, "http://www.ldc.usb.ve/~figueira/Cursos/Seguridad/Expo/OAuth_expo.pdf")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
